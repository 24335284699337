





































































import { apiPcThemePageDetailCC, apiPcThemePageEditCC } from '@/api/shop'
import { copyClipboard } from '@/utils/util'
import { Component, Prop, Vue } from 'vue-property-decorator'
import LinkSelect from '@/components/link-select/index.vue'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
    components: {
        MaterialSelect,
        LinkSelect
    }
})
export default class ShopIndex extends Vue {
    activeName = '2'
    loading = true
    tabs = [
        {
            type: 2,
            label: '登录',
            title: '登录页背景图',
            desc: '建议尺寸：750*440',
            bg: require('@/assets/images/ad_login_img.png'),
            css: {
                left: '148px',
                top: '83px',
                width: '315px',
                height: '192px'
            }
        },
        {
            type: 3,
            label: '限时秒杀',
            title: '限时秒杀广告',
            desc: '建议尺寸：1180*240',
            bg: require('@/assets/images/ad_seckill_img.png'),
            css: {
                left: '153px',
                top: '76px',
                width: '493px',
                height: '101px'
            }
        },
        {
            type: 4,
            label: '领券中心',
            title: '领券中心广告',
            desc: '建议尺寸：1180*240',
            bg: require('@/assets/images/ad_coupon_img.png'),
            css: {
                left: '153px',
                top: '76px',
                width: '493px',
                height: '101px'
            }
        },
        {
            type: 5,
            label: '商城公告',
            title: '商城公告广告',
            desc: '建议尺寸：1180*240',
            bg: require('@/assets/images/ad_notice_img.png'),
            css: {
                left: '153px',
                top: '76px',
                width: '493px',
                height: '101px'
            }
        }
    ]

    page = {
        content: {
            url: '',
            link: {},
            name: ''
        }
    }

    client = 1

    getData() {
        this.loading = true
        this.page = {
            content: {
                url: '',
                link: {},
                name: ''
            }
        }
        apiPcThemePageDetailCC({
            type: Number(this.activeName)
        }).then(res => {
            this.page = res
            this.loading = false
        })
    }

    handleTabClick() {
        this.getData()
    }

    setData() {
        apiPcThemePageEditCC({
            ...this.page,
            content: JSON.stringify(this.page.content)
        }).then(res => {
            this.loading = false
        })
    }

    created() {
        this.getData()
    }
}
